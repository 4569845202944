<template>
    <div>
        <div class="header">
            <van-icon name="arrow-left" @click="$router.push({path: '/self/service/bill'})"/>
            <div>银行卡</div>
        </div>
        <div style="height: 45px; width:100%"></div>
        <div v-for="item in bankList" :key="item.id" class="card" :style="{background: `url(${item.bg})`}" @click="toBank(item.id)">
            <div class="title">
                <div class="img-con">
                    <img :src="item.icon" alt="">
                </div>
                <div>{{ item.title }}</div>
            </div>
            <div class="type">{{ item.type }}</div>
            <div class="no">
                <div class="dot">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="dot">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="dot">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="tail">{{ item.no | filterTail }}</div>
            </div>
        </div>
        <div class="add-bank">
            <div class="icon">+</div>
            <div class="">添加银行卡</div>
            <div class="tip">绑新卡送立减金</div>
        </div>
        <div class="apply">
            <div class="btn">
                <img src="/images/bank-card.png" alt="">
                <div>申请信用卡</div>
            </div>
            <div class="tip">本服务由财付通提供</div>
        </div>
</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

    export default {
        computed: {
            ...mapGetters([
                'bankList'
            ])
        },
        filters: {
            filterTail(no) {
                if (no) {
                    const arr = no.split(',')
                    return arr[arr.length-1]
                } 
                return ''
            }
        },
        methods: {
            ...mapMutations([
                'setActiveCardId'
            ]),
            toBank(id) {
                this.setActiveCardId({ id })
                this.$router.push({ path: '/self/service/bill/banklist/bank' })
            }
        }
    }

</script>

<style lang="less" scoped>
.header {
    position: fixed;
    width: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    // overflow: hidden;
    height: 45px;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    div {
        flex: 1;
        margin-left: -17px;
    }
}
.card {
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 10px;
    .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        padding-top: 10px;
        .img-con {
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            margin-left: 10px;
        }
        img {
            width: 16px;
            height: 16px;
        }
    }
    .type {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 36px;
    }
    .no {
        margin-left: 36px;
        display: flex;
        align-items: center;
       .dot {
            display: flex;
            align-items: center;
            margin-right: 12px;
            div {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #fff;
                margin-right: 4px;
            }
       }
        .tail {
            color: #fff;
            font-size: 30px;
        }
    }
}
.add-bank {
    margin-top: 10px;
    background: #fff;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    color: rgba(30, 29, 29, 1);
    .icon {
        font-size: 30px;
        margin-right: 10px;
    }
    .tip {
        font-size: 12px;
        color: #F1B067;
        margin-left: 8px;
    }

}
.apply {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
        img {
            width: 10px;
            height: 10px;
            margin-right: 6px;
        }
        color: #6a7ba1;
        font-size: 12px;
    }
    .tip {
        font-size: 10px;
        color: #545454;
        margin-bottom: 4px;
    }
}
</style>